import React from "react";
import PineTree from "../../assets/images/pinetree.svg";

const SupportBanner = () => {
  return (
    <section className="christmas-banner-container">
      <div className="pinetree">
        <img src={PineTree} alt="Christmas tree" width="53" height="87" />
      </div>
      <div className="christmas-banner-content">
        <h1>Broker support Christmas opening hours </h1>

        <div className="christmas-banner-content__dates">
          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              24<sup>th</sup> December
            </span>
            <span className="christmas-banner__date">9am - 5pm</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              25<sup>th</sup> December
            </span>
            <span className="christmas-banner-content__closed">CLOSED</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              26<sup>th</sup> December
            </span>
            <span className="christmas-banner-content__closed">CLOSED</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              27<sup>th</sup> December
            </span>
            <span className="christmas-banner-content__date">9am - 5pm</span>
          </div>
          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              30<sup>th</sup> December
            </span>
            <span className="christmas-banner-content__date">9am - 5pm</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              31<sup>st</sup> December
            </span>
            <span className="christmas-banner-content__date">9am - 5pm</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              1<sup>st</sup> January 2025
            </span>
            <span className="christmas-banner-content__closed">CLOSED</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              2<sup>nd</sup> January 2025
            </span>
            <span className="christmas-banner-content__date">9am - 5pm</span>
          </div>

          <div className="christmas-banner-content__column">
            <span className="christmas-banner-content__date">
              3<sup>rd</sup> January 2025
            </span>
            <span className="christmas-banner-content__time">9am - 5pm</span>
          </div>
        </div>
      </div>
      <div className="pinetree">
        <img src={PineTree} alt="Christmas tree" width="53" height="87" />
      </div>
    </section>
  );
};

export default SupportBanner;
